import * as React from "react";

import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { about, content, contentFlex, image, paragraph } from './valpar.module.scss';

import BottomSpace from "../components/bottomSpace";

import Zoom from '../components/Zoom';

const IndexPage = () => {
    return (
        <Layout pageTitle="Valpar" largeHero={true}>
            <div className={content}>
                <div className={contentFlex}>
                    <div className={about}>
                        <h1>Valpplaner</h1>
                        <p><i>
                            Valpar planeras mellan Lady och Pascal under hösten 2024.
                        </i></p>

                        <h3>Pascal</h3>

                        <p className={paragraph}>
                            e:  Engine Encanto
                            <br />
                            Mellanpudelhane, svart, 40 cm
                            <br />
                            Född 2023-03-17
                            <br />
                            SE24304/2023
                            <br />
                            <br />
                            e. Engine Eclipse
                            <br />
                            u. DK UCH EE CH FI UCH LD STARTKLASS RLD N SE UCH Engine Fourth Dimension Tessaract
                            <br />
                            <br />
                            HD: A/A
                            <br />
                            Patella: UA
                            <br />
                            PRCD-pra: A (fri)
                            <br />
                            <br />
                            rcd4 PRA: A (fri)
                            <br />
                            <br />
                            Ögon: UA
                            <br />
                            <br />
                            Fulltandad, korrekt bett
                            <br />
                            <br />
                            BPH: Genomfört med skott
                            <br />

                            <h3>Lady</h3>

                            u. Amore Vincit Omnia Fire And Blood
                            <br />
                            Dvärgpudel, aprikosröd, 35 cm
                            <br />
                            <br />
                            e: Engine Xander (multichampion)
                            <br />
                            u: Sagotassens Beskow
                            <br />
                            <br />
                            Ögon: Distichiasis
                            <br />
                            patella, ua
                            <br />
                            PRCD-pra: A (fri)
                            <br />
                            <br />
                            Fulltandad, korrekt bett
                        </p>
                    </div>

                    <Zoom wrapperClass={image}>
                        <StaticImage loading="eager" src={'../images/lady_pascal.png'} alt={"Lady och Pascal"} />
                    </Zoom>
                </div>

            </div>

            <BottomSpace />
        </Layout>
    )
}

export default IndexPage